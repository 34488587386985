import styled from 'styled-components';
import { Content } from 'components/atoms/Content';
import { useTranslation } from 'next-i18next';
import { Breakpoints } from 'styles/theme';
import Link from 'next/link';

const MainSection = styled.section`
    display: flex;
    text-align: center;
    padding: 60px 0;

    @media ${Breakpoints.lg} {
        padding: 100px 0;

        h1 {
            font-size: 80px;
            margin: 0;
        }

        p {
            font-size: 30px;
            max-width: 960px;
        }
    }
`;

export const FulfillmentServiceSection: React.FunctionComponent = () => {
    const { t } = useTranslation('home');

    return (
        <MainSection>
            <Content>
                <h1>{t('MagicShip Fulfilment Service')}</h1>
                <p>
                    {t(
                        "Let us handle shipping and delight your customers with fast & cheap 1-3 days delivery across Canada, and cheaper international shipments. It's easy: send your items to one or more of our warehouses across Canada, and we will handle shipping for you."
                    )}
                    <br />
                    <br />
                    <Link href="/fulfillment-service/canada">
                        <a>{t('Learn more')}</a>
                    </Link>
                </p>
            </Content>
        </MainSection>
    );
};
