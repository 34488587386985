import styled from 'styled-components';
import { Content } from 'components/atoms/Content';
import { useTranslation } from 'next-i18next';
import { Button } from 'components/atoms/Button';
import { Breakpoints } from 'styles/theme';
import { useCallback } from 'react';
import { useModalContext } from '../login/ModalContext';

const MainSection = styled.section`
    display: flex;
    text-align: center;
    color: ${({ theme }) => theme.text.contrast};

    h3 {
        font-size: 30px;
        font-weight: 500;
        margin: 0 0 1.5rem;

        @media ${Breakpoints.md} {
            margin: 0 2rem 0 0;
        }
    }

    @media ${Breakpoints.lg} {
        padding: 60px 0;
    }
`;

const StyledContent = styled(Content)`
    background: ${({ theme }) => theme.colors.secondary};
    padding: 40px 0;

    @media ${Breakpoints.md} {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

export const SignupSection: React.FunctionComponent = () => {
    const { showModal } = useModalContext();

    const { t } = useTranslation('home');

    const handleSignup = useCallback(() => {
        showModal('signup');
    }, [showModal]);

    return (
        <MainSection>
            <StyledContent>
                <h3>{t('Sign up for an account today')}</h3>
                <Button onClick={handleSignup} size="lg" kind="contrast">
                    {t('Sign up')}
                </Button>
            </StyledContent>
        </MainSection>
    );
};
