import { Content } from 'components/atoms/Content';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';
import Image from 'next/image';
import dimensionsImage from 'public/home/dimensions.svg';
import shippingLabelImage from 'public/home/shipping-label.svg';
import shippingLogoImage from 'public/home/shipping-logo.svg';

const Container = styled.section`
    display: flex;
    text-align: center;
    padding: 60px 0;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.text.contrast};

    @media ${Breakpoints.lg} {
        padding: 80px 0;

        h1 {
            font-size: 80px;
            margin: 0 0 3rem;
        }
    }
`;

const StepIndexLabel = styled.div`
    text-transform: uppercase;
    margin: 1rem 0;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin: 2rem 0;
    flex-grow: 1;
    flex-basis: 0;
    @media ${Breakpoints.lg} {
        margin: 0 2rem;
    }
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 960px;

    @media ${Breakpoints.lg} {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
    }
`;

export const StepsSection: React.FunctionComponent = () => {
    const { t } = useTranslation('home');
    const steps = [
        {
            image: dimensionsImage,
            label: t('Enter shipment details'),
        },
        {
            image: shippingLabelImage,
            label: t('Print the shipping label (thermal or paper) and stick it to the box'),
        },
        {
            image: shippingLogoImage,
            label: t('Drop-off at the carrier(s) or schedule pick-up(s) for them to come'),
        },
    ];
    return (
        <Container>
            <Content>
                <h1>{t('Step-by-Step')}</h1>
                <ItemsContainer>
                    {steps.map((x, i) => {
                        return (
                            <ItemContainer key={i}>
                                <Image src={x.image} alt="icon" width="172" height="172" />
                                <StepIndexLabel>{`${t('Step')} ${i + 1}`}</StepIndexLabel>
                                <div>{x.label}</div>
                            </ItemContainer>
                        );
                    })}
                </ItemsContainer>
            </Content>
        </Container>
    );
};
