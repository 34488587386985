import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { CountrySelect } from 'components/atoms/CountrySelect';
import { Controller, useFormContext } from 'react-hook-form';
import { ShipmentInput } from '@magicship/utils/shipment';
import { FormError } from 'components/atoms/FormError';
import { useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { findLocationByPostalCode } from 'components/utils/geocoding';

type Props = {
    name: 'from' | 'to';
    requireCity?: boolean;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        width: 100%;
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
`;

export const LocationInput: React.FunctionComponent<Props> = ({ name, requireCity }: Props) => {
    const { t } = useTranslation(['home', 'common']);
    const {
        register,
        control,
        setValue,
        formState: { errors },
    } = useFormContext<ShipmentInput>();

    const postalCodeError = errors[name]?.postalCode?.message ?? '';
    const countryCodeError = errors[name]?.countryCode?.message ?? '';
    const cityError = errors[name]?.city?.message ?? '';

    const handleBlurPostalCode = useCallback(
        async (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value && e.target.value.length > 2) {
                try {
                    const geoResult = await findLocationByPostalCode(
                        e.target.value,
                        name === 'from' ? 'CA' : undefined
                    );
                    if (geoResult) {
                        setValue(`${name}.countryCode`, geoResult.countryCode);
                        setValue(`${name}.stateCode`, geoResult.stateCode);
                        setValue(`${name}.city`, geoResult.city);
                    }
                } catch (e) {}
            }
        },
        [name, setValue]
    );

    const debouncedChange = useMemo(() => debounce(handleBlurPostalCode, 300), [handleBlurPostalCode]);
    const requiredMessage = t<string>('common::Required field');

    return (
        <Container>
            <div>
                <Input
                    className="data-hj-allow"
                    error={Boolean(postalCodeError)}
                    placeholder={t('Postal Code')}
                    type="text"
                    {...register(`${name}.postalCode`, {
                        required: requiredMessage,
                        onChange: debouncedChange,
                    })}
                />
                {postalCodeError && <FormError>{postalCodeError}</FormError>}
            </div>
            {requireCity && (
                <div>
                    <Input
                        className="data-hj-allow"
                        error={Boolean(postalCodeError)}
                        placeholder={t('City')}
                        type="text"
                        {...register(`${name}.city`, {
                            required: requiredMessage,
                        })}
                    />
                    {cityError && <FormError>{cityError}</FormError>}
                </div>
            )}
            <div>
                <Controller
                    render={({ field, formState }) => (
                        <CountrySelect
                            error={Boolean(formState.errors[name]?.countryCode)}
                            name={field.name}
                            inputRef={field.ref}
                            value={name === 'from' ? 'CA' : field.value}
                            onBlur={field.onBlur}
                            values={name === 'from' ? ['CA', 'US'] : undefined}
                            onChange={(value) => {
                                field.onChange(value);
                                setValue(`${name}.stateCode`, '');
                            }}
                        />
                    )}
                    control={control}
                    name={`${name}.countryCode`}
                    rules={{ required: requiredMessage }}
                />
                {countryCodeError && <FormError>{countryCodeError}</FormError>}
            </div>
        </Container>
    );
};
