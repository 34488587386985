import styled from 'styled-components';
import { Content } from 'components/atoms/Content';
import { useTranslation } from 'next-i18next';
import { Breakpoints } from 'styles/theme';
import Image from 'next/image';
import { ShippingService } from '~graphql/API';
import { carrierLogoMap } from './calculator/ShipmentOptions/common';

const MainSection = styled.section`
    display: flex;
    text-align: center;
    padding: 60px 0;

    @media ${Breakpoints.lg} {
        padding: 100px 0;

        h1 {
            font-size: 80px;
            margin: 0;
        }

        p {
            font-size: 30px;
            max-width: 960px;
        }
    }
`;

const CarriersIcons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const CarrierIcon = styled.div`
    padding: 10px;
`;

const carriers = [ShippingService.CanadaPost, ShippingService.UPS, ShippingService.Canpar, ShippingService.ICS];

export const CarriersSection: React.FunctionComponent = () => {
    const { t } = useTranslation('home');

    return (
        <MainSection>
            <Content>
                <h1>{t('Connect your own carriers')}</h1>
                <p>
                    {t(
                        'On top of our re-seller rates with carriers, you can connect your own carriers such as UPS, Canada Post, Canpar, and ICS.'
                    )}
                </p>
                <CarriersIcons>
                    {carriers.map((service) => {
                        const logo = carrierLogoMap[service];
                        return (
                            <CarrierIcon key={service}>
                                <Image
                                    src={logo}
                                    alt={service}
                                    width="132"
                                    height="68"
                                    objectPosition="center"
                                    objectFit="contain"
                                />
                            </CarrierIcon>
                        );
                    })}
                </CarriersIcons>
            </Content>
        </MainSection>
    );
};
