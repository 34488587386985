import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { TitleSection } from 'components/parts/home/TitleSection';
import { CalculatorSection } from 'components/parts/home/calculator/CalculatorSection';
import React from 'react';
import { SSRConfig } from 'next-i18next';
import { StepsSection } from 'components/parts/home/StepsSection';
import { CashbackSection } from 'components/parts/home/CashbackSection';
import { SignupSection } from 'components/parts/home/SignupSection';
import { FulfillmentServiceSection } from 'components/parts/home/FulfillmentServiceSection';
import { BasicPage } from 'components/parts/BasicPage';
import dynamic from 'next/dynamic';
import { CarriersSection } from 'components/parts/home/CarriersSection';

const TestimonialsSection = dynamic(() => import('components/parts/home/TestimonialsSection'));

function Home(): React.ReactNode {
    return (
        <BasicPage>
            <TitleSection />
            <CalculatorSection />
            <StepsSection />
            <CarriersSection />
            <CashbackSection />
            <TestimonialsSection />
            <SignupSection />
            <FulfillmentServiceSection />
        </BasicPage>
    );
}

export async function getStaticProps({ locale }: { locale: string }): Promise<{ props: SSRConfig }> {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common', 'home', 'shipping'])),
        },
    };
}

export default Home;
