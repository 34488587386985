import styled from 'styled-components';
import { Content } from 'components/atoms/Content';
import { useTranslation } from 'next-i18next';
import { Breakpoints } from 'styles/theme';

const MainSection = styled.section`
    display: flex;
    text-align: center;
    padding: 60px 0;

    @media ${Breakpoints.lg} {
        padding: 100px 0;

        h1 {
            font-size: 80px;
            margin: 0;
        }

        p {
            font-size: 30px;
            max-width: 960px;
        }
    }
`;

export const TitleSection: React.FunctionComponent = () => {
    const { t } = useTranslation('home');
    return (
        <MainSection>
            <Content>
                <h1>{t('Save Money on Shipping')}</h1>
                <p>
                    {t(
                        'Ship worldwide from Canada, with the best rates and the best customer service in the industry. Get highly discounted shipping labels for UPS and Canada Post.'
                    )}
                </p>
            </Content>
        </MainSection>
    );
};
