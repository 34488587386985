import styled from 'styled-components';
import { Content } from 'components/atoms/Content';
import { useTranslation } from 'next-i18next';
import { Breakpoints } from 'styles/theme';
import bgImage from 'public/home/cashback-bg.jpg';

const MainSection = styled.section`
    display: flex;
    text-align: center;
    padding: 60px 0;
    background: url('${bgImage.src}');
    background-position: center;
    background-size: 100%;
    color: ${({ theme }) => theme.text.contrast};
    position: relative;

    &:before {
        background: rgba(44, 41, 106, 0.7);
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    > * {
        z-index: 1;
    }

    @media ${Breakpoints.lg} {
        padding: 120px 0;

        h1 {
            font-size: 80px;
            margin: 0;
        }

        p {
            font-size: 20px;
            font-weight: 700;
        }
    }
`;

export const CashbackSection: React.FunctionComponent = () => {
    const { t } = useTranslation('home');
    return (
        <MainSection>
            <Content>
                <h1>{t('CASH BACK')}</h1>
                <p>{t('cashbackMessage')}</p>
            </Content>
        </MainSection>
    );
};
