import { FormRow } from 'components/atoms/FormRow';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';
import { SectionTitle } from '../SectionTitle';
import { LocationInput } from './LocationInput';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${Breakpoints.lg} {
        max-width: 348px;
        margin-right: 72px;
    }
`;

type Props = {
    requireCityFrom: boolean;
    requireCityTo: boolean;
};

export const Location: React.FunctionComponent<Props> = ({ requireCityFrom, requireCityTo }: Props) => {
    const { t } = useTranslation('common');
    return (
        <Container>
            <SectionTitle>{t('Location')}</SectionTitle>
            <FormRow label={t('From')}>
                <LocationInput name="from" requireCity={requireCityFrom} />
            </FormRow>
            <FormRow label={t('To')}>
                <LocationInput name="to" requireCity={requireCityTo} />
            </FormRow>
        </Container>
    );
};
